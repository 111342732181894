.experienceContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
}

.experiences {
  background: var(--color-bg-variant);
  padding: 2.4rem 5rem;
  border-radius: 2rem;
  border: 1px solid transparent;
  transition: var(--transition);
}

.experiences:hover {
  background: transparent;
  border-color: var(--color-primary-variant);
  cursor: default;
}

.experiences h3 {
  text-align: center;
  margin-bottom: 2rem;
  color: var(--color-primary);
}

.experienceContent {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 2rem;
}

.experienceDetails {
  display: flex;
  gap: 1rem;
}

.experienceDetailsIcon {
  margin-top: 0.35rem;
  color: var(--color-primary);
}

/*=================== MEDIA QUERIES (MEDIUM DEVICES) ===================*/
@media screen and (max-width: 1024px) {
  .experienceContainer {
    grid-template-columns: 1fr;
  }

  .experiences {
    width: 80%;
    padding: 2rem;
    margin: 0 auto;
  }

  .experienceContent {
    padding: 1rem;
  }
}

/*=================== MEDIA QUERIES (SMALL DEVICES) ===================*/
@media screen and (max-width: 600px) {
  .experienceContainer {
    gap: 1rem;
  }
  .experiences {
    width: 100%;
    padding: 2rem 1rem;
  }
}
