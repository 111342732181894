.portfolioContainer {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 2.5rem;
}

.portfolioItem {
  background: var(--color-bg-variant);
  padding: 1.2rem;
  border-radius: 2rem;
  border: 1px solid transparent;
  transition: var(--transition);
}

.portfolioItem:hover {
  border-color: var(--color-primary-variant);
  background: transparent;
}

.portfolioItemImage {
  border-radius: 1.5rem;
  overflow: hidden;
}

.portfolioItem h3 {
  margin: 1.2rem 0 2rem;
  text-align: center;
}

.portfolioItemCta {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.portfolioButton {
  width: 70%;
  text-align: center;
}
