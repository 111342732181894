.superHead {
  font-size: 1.5rem;
}

.superpower {
  color: var(--color-high);
  font-size: 3rem;
}

.blinkingCursor::after {
  content: "|";
  color: var(--color-primary);
  animation: blink 1s step-start infinite;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}
