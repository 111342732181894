.contactContainer {
  width: 58%;
  display: grid;
  grid-template-columns: 30% 58%;
  gap: 12%;
}

.contactOptions {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

.contactOption {
  background: var(--color-bg-variant);
  padding: 1.2rem;
  border-radius: 1.2rem;
  text-align: center;
  border: 1px solid transparent;
  transition: var(--transition);
}

.contactOption:hover {
  background: transparent;
  border-color: var(--color-primary-variant);
}

.contactOptionIcon {
  font-size: 1.5rem;
}

.contactOption a {
  margin-top: 0.7rem;
  display: inline-block;
  font-size: 0.8rem;
}

.contactForm {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

.contactEmail {
  color: var(--color-gmail);
}

.contactMessenger {
  color: var(--color-facebook);
}

.contactWhatsapp {
  color: var(--color-whatsapp);
}

.contactData {
  color: var(--color-primary-variant);
}

input,
textarea {
  width: 100%;
  padding: 1.5rem;
  border-radius: 0.5rem;
  background: transparent;
  border: 2px solid var(--color-bg-variant);
  resize: none;
  color: var(--color-primary-variant);
  transition: var(--transition);
}

::placeholder {
  color: var(--color-primary-variant);
  font-size: 0.8rem;
}

input:focus,
textarea:focus {
  border: 2px solid var(--color-primary-variant);
}
/*=================== MEDIA QUERIES (MEDIUM DEVICES) ===================*/
@media screen and (max-width: 1024px) {
  .contactContainer {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
}

/*=================== MEDIA QUERIES (SMALL DEVICES) ===================*/
@media screen and (max-width: 600px) {
  .contactContainer {
    width: var(--container-width-sm);
  }
}
