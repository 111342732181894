header {
  height: 100vh;
  padding-top: 5rem;
  overflow: hidden;
}

.headerContainer {
  text-align: center;
  height: 100%;
  position: relative;
}

.hello {
  font-size: 1.5rem;
}
.sag {
  font-size: 3rem;
}
.dev {
  font-size: 1.5rem;
}
.me {
  margin-top: 3rem;
  overflow: hidden;
  padding: 1rem;
}

.scrollDown {
  position: absolute;
  right: -2.3rem;
  bottom: 5rem;
  transform: rotate(90deg);
  font-weight: 300;
  font-size: 0.9rem;
}

/*=================== MEDIA QUERIES (MEDIUM DEVICES) ===================*/
@media screen and (max-width: 1024px) {
  header {
    height: 68vh;
  }
}

/*=================== MEDIA QUERIES (SMALL DEVICES) ===================*/
@media screen and (max-width: 600px) {
  header {
    height: 100vh;
  }
  .scrollDown {
    display: none;
  }
}
