.social {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.8rem;
  position: absolute;
  left: 0;
  bottom: 3rem;
}

.social::after {
  content: "";
  width: 1px;
  height: 2rem;
  background: var(--color-primary);
}

@media screen and (max-width: 600px) {
  .social {
    display: none;
  }
}
